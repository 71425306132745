<template>
  <main class="page page--billing">
    <loader
      :loading="!$store.getters['user/exist']"
      :error="$store.getters['user/error']"
    />
    <section class="section section--billing billing">
      <div class="billing__wrapper">
        <h1 class="billing__title title title-lg">
          {{ $t('web.web_payment_history') }}
        </h1>
        <div class="billing__content billing-content">
          <div class="billing__transactions">
            <div class="transactions__table">
              <div class="transactions__thead">
                <div class="transactions__tr color-main-third">
                  <div class="transactions__th text text-sm">
                    {{ $t('web.date') }}
                  </div>
                  <div class="transactions__th text text-sm">
                    {{ $t('web.tariff') }}
                  </div>
                  <div class="transactions__th text text-sm">
                    {{ $t('web.payment_method') }}
                  </div>
                  <div class="transactions__th text text-sm">
                    {{ $t('web.status') }}
                  </div>
                  <div class="transactions__th text text-sm">
                    {{ $t('web.sum') }}
                  </div>
                </div>
              </div>
              <div v-if="transactions.length > 0" class="transactions__tbody">
                <div
                  v-for="transaction in transactions"
                  :key="transaction.id"
                  class="transactions__tr"
                >
                  <div class="transactions__td text text-xs">
                    {{ $moment(transaction.created).format('L') }}
                  </div>
                  <div class="transactions__td text text-xs">
                    {{ transaction.title }}
                  </div>
                  <div class="transactions__td text text-xs">
                    {{ transaction.service }}
                  </div>
                  <div
                    class="
                      transactions__td
                      text text-xs text-bold
                      transactions__status
                    "
                    :class="{
                      'transactions__status--color-success':
                        transaction.status ===
                        $CONSTANTS.TRANSACTIONS.STATUS.PAID,
                      'transactions__status--color-fail': [
                        $CONSTANTS.TRANSACTIONS.STATUS.NOT_PAID,
                        $CONSTANTS.TRANSACTIONS.FAILED,
                      ].includes(transaction.status),
                      'transactions__status--color-trial':
                        transaction.status ===
                        $CONSTANTS.TRANSACTIONS.STATUS.TRIAL,
                      'transactions__status--color-default': ![
                        $CONSTANTS.TRANSACTIONS.STATUS.PAID,
                        $CONSTANTS.TRANSACTIONS.STATUS.NOT_PAID,
                        $CONSTANTS.TRANSACTIONS.STATUS.TRIAL,
                      ].includes(transaction.status),
                    }"
                  >
                    <span class="history__paid">{{
                      translate('status', transaction.status)
                    }}</span>
                  </div>
                  <div class="transactions__td">
                    <div class="history__price text text-xs text-bold">
                      {{ transaction.currency }} {{ transaction.amount }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="transactions__tbody transactions__tbody--empty"
              >
                <div class="transactions__empty-content">
                  <div>
                    <span class="text text-sm">
                      {{ $t('web.tbody_is_empty_1') }}.
                    </span>
                  </div>
                  <div>
                    <c-link
                      :to="{ name: $ROUTER.NAME.PRICES }"
                      nowrap
                      class="text text-sm"
                      :label="$t('web.tbody_is_empty_2')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="billing__plans">
            <current-plan-info
              class="transactions__current-plan-info"
            ></current-plan-info>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CLink from '@/components/Link'
import CurrentPlanInfo from './CurrentPlanInfo'
import currencyToSymbolMap from '@/utils/currencyToSymbolMap'
import { isInt } from '@/utils/number'
import Loader from '../components/UserLoader'

export default {
  name: 'Billing',
  components: {
    CurrentPlanInfo,
    CLink,
    Loader,
  },

  methods: {
    translate(field, value) {
      const _value = value.replaceAll(' ', '-')
      switch (field) {
        case 'status':
          return (
            {
              paid: this.$t('web.status_paid'),
              'not-paid': this.$t('web.status_not-paid'),
              failed: this.$t('web.status_failed'),
              trial: this.$t('web.status_trial'),
              refund: this.$t('web.status_refund'),
            }[_value.toLowerCase()] || _value.toLowerCase()
          )
        default:
          return _value.toLowerCase()
      }
    },
  },

  computed: {
    transactions: {
      get() {
        return this.$store.getters['user/transactions'].map((transaction) => {
          return {
            ...transaction,
            currency:
              currencyToSymbolMap[transaction.currency] || transaction.currency,
            amount: isInt(+transaction.amount)
              ? parseFloat(transaction.amount)
              : transaction.amount,
          }
        })
      },
    },
  },
}
</script>

<style lang="scss">
@import 'index';
</style>
