<template>
  <div v-if="loading" class="loader">
    <div v-if="error" class="text-center">
      {{ error }}
    </div>
    <div v-else class="loader__progress-wrapper">
      <div class="loader__loading loading">
        <div class="loading__dot loading__dot--first"></div>
        <div class="loading__dot loading__dot--second"></div>
        <div class="loading__dot loading__dot--third"></div>
        <div class="loading__dot loading__dot--fourth"></div>
        <div class="loading__dot loading__dot--fifth"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLoader',
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    error: {
      default: '',
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.loader {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 298;

  &__progress-wrapper {
    position: relative;
    padding-bottom: 48px;
  }

  &__loading {
    position: absolute;
    z-index: 299;
    width: 100px;
    margin: 0 -50px;
    height: 24px;
    bottom: 0;
    &.loading {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .loading {
      &__dot {
        transform: scale(0, 0);
        border-radius: 50%;
        background-color: var(--main);
        animation: loading-dot 1s infinite ease-in-out;
        &--first {
          animation-delay: 0s;
          height: 10px;
          width: 10px;
        }
        &--second {
          animation-delay: 0.15s;
          height: 16px;
          width: 16px;
        }
        &--third {
          animation-delay: 0.3s;
          height: 22px;
          width: 22px;
        }
        &--fourth {
          animation-delay: 0.45s;
          height: 16px;
          width: 16px;
        }
        &--fifth {
          animation-delay: 0.6s;
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  @keyframes loading-dot {
    0% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    35% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    70% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    100% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
  }
}
</style>
