<template>
  <div>
    <div v-if="computedCurrentPlans.length > 0">
      <div
        class="current-plan"
        v-for="(currentPlan, index) in computedCurrentPlans"
        :key="index"
      >
        <div class="current-plan__tile-block tile-block">
          <h3 class="tile-block__title text text-sm">
            {{ $t('web.current-plan') }}:
            <span class="text-bold">{{ currentPlan.name }}</span>
          </h3>
          <p class="tile-block__item text text-sm">
            {{ $t('web.date') }}:
            {{ subscriptionDate(index) }}
          </p>
          <p class="tile-block__item text text-sm">
            {{ $t('web.price') }}: ${{ currentPlan.currentPrice.value }}
          </p>
          <p
            v-if="toShowPriceFrom(index)"
            class="tile-block__item text text-sm"
          >
            {{ $t('web.price_from') }}: {{ nextPrice(index) }}
          </p>
        </div>
        <div
          v-if="isPaymentMethodExist(index)"
          class="current-plan__tile-block tile-block"
        >
          <div v-if="!waitingForCancel(index)">
            <h3 class="tile-block__title text text-sm">
              {{ $t('web.payment') }}:
              <span class="text-bold text-sm text-capitalize">{{
                getPaymentMethod(currentPlan.paymentMethod.name)
              }}</span>
            </h3>
            <p v-if="nextDueDate(index)" class="tile-block__item text text-sm">
              {{ $t('web.next_due') }}: {{ nextDueDate(index) }}
            </p>
            <p v-if="nextDueSum(index)" class="tile-block__item text text-sm">
              {{ $t('web.price') }}: {{ nextDueSum(index) }}
            </p>
            <p class="tile-block__item text text-sm">
              <span
                class="tile-block__link"
                @click="cancelSubscription(index)"
                >{{ $t('web.action.cancel') }}</span
              >
            </p>
          </div>
          <div class="loader__wrapper" v-else>
            <p class="tile-block__item text text-sm text-break-spaces">
              {{ $t('billings-page.waiting-processing') }}
            </p>
            <div class="loader">
              <div class="loader__progress-wrapper">
                <div class="loader__loading loading">
                  <div class="loading__dot loading__dot--first"></div>
                  <div class="loading__dot loading__dot--second"></div>
                  <div class="loading__dot loading__dot--third"></div>
                  <div class="loading__dot loading__dot--fourth"></div>
                  <div class="loading__dot loading__dot--fifth"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="!dataIsLoaded"
      class="
        current-plan__tile-block current-plan__tile-block-button
        tile-block
      "
    ></div>
  </div>
</template>

<script>
import { PAYMENT } from '@/CONSTANTS'

const METHOD_NAME = {
  APPLE_STORE: 'Apple Store',
  PLAY_MARKET: 'Google Play',
  CARD: 'Card',
  INTERNET_WALLET: 'Internet Wallet',
  PROMO: 'Promo',
}

const MAX_CANCELLATION_TIMOUT = 30000
const CANCELLATION_TIMER_STEP = 3000

export default {
  name: 'CurrentPlanInfo',

  data: function () {
    return {
      isCancellationProcessing: false,
      paymentMethodNames: {
        [PAYMENT.SERVICE.APPLE]: METHOD_NAME.APPLE_STORE,
        [PAYMENT.SERVICE.GOOGLE]: METHOD_NAME.PLAY_MARKET,
        [PAYMENT.SERVICE.COMMERCE]: METHOD_NAME.CARD,
        [PAYMENT.SERVICE.ORANGEPAY]: METHOD_NAME.CARD,
        [PAYMENT.SERVICE.COINPAYMENT]: METHOD_NAME.CARD,
        [PAYMENT.SERVICE.CENTROBILL]: METHOD_NAME.CARD,
        [PAYMENT.SERVICE.ACCESSPAY]: METHOD_NAME.CARD,
        [PAYMENT.SERVICE.STRIPE]: METHOD_NAME.CARD,
        [PAYMENT.SERVICE.FREE_KASSA]: METHOD_NAME.INTERNET_WALLET,
        [PAYMENT.SERVICE.PROMO]: METHOD_NAME.PROMO,
      },
    }
  },

  computed: {
    computedCurrentPlans: {
      get() {
        return (
          this.currentPlans?.filter((_, index) =>
            this.isCurrentPlanExist(index)
          ) || []
        )
      },
    },
    currentPlans: {
      get() {
        return this.$store.getters['user/currentPlans']
      },
    },
    dataIsLoaded: {
      get() {
        return !!this.currentPlans
      },
    },
  },

  mounted() {
    this.isCancellationProcessing = JSON.parse(
      localStorage.getItem('isCancellationProcessing') || 'false'
    )
    const plansToCancelCreated =
      localStorage.getItem('plansToCancelCreated') || new Date()
    if (
      this.isCancellationProcessing &&
      new Date() - plansToCancelCreated <= MAX_CANCELLATION_TIMOUT
    ) {
      this.processCancellation(false)
    } else {
      this.resetCancellationProcessing()
    }
  },

  unmounted() {
    clearTimeout(this.updatePlansTimeout)
    clearTimeout(this.updatePlansTimeoutWhenError)
  },

  methods: {
    resetCancellationProcessing() {
      localStorage.removeItem('isCancellationProcessing')
      localStorage.removeItem('plansToCancelCreated')
      localStorage.removeItem('plansToCancel')
      this.isCancellationProcessing = false
    },
    setCancellationProcessing(planID) {
      const valueFromStorage = JSON.parse(
        localStorage.getItem('isCancellationProcessing') || 'false'
      )
      if (!valueFromStorage) {
        this.updatePlans()
      }
      const arr = JSON.parse(localStorage.getItem('plansToCancel') || '[]')
      if (!arr.includes(planID)) {
        localStorage.setItem('plansToCancel', JSON.stringify([...arr, planID]))
        localStorage.setItem('plansToCancelCreated', new Date())
      }
      this.isCancellationProcessing = true
    },
    processCancellation(toShowNotification = true) {
      const plansToCancel = JSON.parse(
        localStorage.getItem('plansToCancel') || '[]'
      )
      const cancelled = plansToCancel.every((planID) => {
        const plan = this.currentPlans.find((plan) => plan.order_id === planID)
        const planIsCanceled =
          !plan?.paymentMethod || !plan?.subscription.isActive

        if (planIsCanceled) {
          const _plansToCancel = JSON.parse(
            localStorage.getItem('plansToCancel') || '[]'
          )
          localStorage.setItem(
            'plansToCancel',
            JSON.stringify(
              _plansToCancel.splice(_plansToCancel.indexOf(planID), 1)
            )
          )
        }
        return planIsCanceled
      })
      if (cancelled) {
        this.resetCancellationProcessing()
        if (toShowNotification) {
          this.$notify({
            group: 'message',
            type: 'success',
            duration: 4500,
            title: this.$t('web.subscription_canceled_successfully'),
          })
        }
      } else {
        const cancelTimer =
          new Date() - new Date(localStorage.getItem('plansToCancelCreated'))
        if (cancelTimer >= MAX_CANCELLATION_TIMOUT) {
          this.resetCancellationProcessing()
          if (toShowNotification) {
            this.$notify({
              group: 'message',
              type: 'error',
              duration: 30000,
              title: this.$t('web.subscription_canceled_failure'),
            })
          }
        } else {
          this.updatePlansTimeout = setTimeout(
            this.updatePlans,
            CANCELLATION_TIMER_STEP
          )
        }
      }
    },
    updatePlans() {
      this.$store
        .dispatch('user/fetchUser')
        .then(() => {
          this.processCancellation()
        })
        .catch(() => {
          const cancelTimer =
            new Date() - new Date(localStorage.getItem('plansToCancelCreated'))
          if (cancelTimer >= MAX_CANCELLATION_TIMOUT) {
            this.resetCancellationProcessing()
            this.$notify({
              group: 'message',
              type: 'error',
              duration: 30000,
              title: this.$t('web.subscription_canceled_failure'),
            })
          } else {
            this.updatePlansTimeoutWhenError = setTimeout(
              this.updatePlans,
              CANCELLATION_TIMER_STEP
            )
          }
        })
    },
    getPaymentMethod(serviceName) {
      return this.paymentMethodNames[serviceName] || serviceName
    },
    nextPrice(index) {
      if (!this.computedCurrentPlans[index].nextPrice) {
        return null
      }
      const date = this.nextPriceDate(index)
        ? this.$moment(this.nextPriceDate(index)).format('DD/MM/YY')
        : ''
      const price = this.computedCurrentPlans[index].nextPrice.value
        ? `$${this.computedCurrentPlans[index].nextPrice.value}`
        : ''
      const separator =
        this.nextPriceDate(index) &&
        this.computedCurrentPlans[index].nextPrice.value
          ? ' - '
          : ''
      return date + separator + price
    },
    subscriptionDate(index) {
      if (!this.computedCurrentPlans[index].subscription.date) {
        return null
      }
      return this.$moment(
        this.computedCurrentPlans[index].subscription.date
      ).format('DD/MM/YY')
    },
    nextPriceDate(index) {
      if (!this.computedCurrentPlans[index].nextPrice?.date) {
        return null
      }
      return this.computedCurrentPlans[index].nextPrice.date
    },
    isCurrentPlanExist(index) {
      return !!this.currentPlans[index]?.name
    },
    isPaymentMethodExist(index) {
      return (
        !!this.computedCurrentPlans[index]?.paymentMethod &&
        !!this.computedCurrentPlans[index].subscription.isActive
      )
    },
    waitingForCancel(index) {
      return (
        this.isCancellationProcessing &&
        !!JSON.parse(localStorage.getItem('plansToCancel') || '[]').find(
          (planID) => planID === this.computedCurrentPlans[index]?.order_id
        )
      )
    },
    toShowPriceFrom(index) {
      return (
        this.computedCurrentPlans[index].nextPrice?.value &&
        this.computedCurrentPlans[index].currentPrice.value !==
          this.computedCurrentPlans[index].nextPrice.value
      )
    },
    nextDueSum(index) {
      return `$${this.computedCurrentPlans[index].paymentMethod.price}` || ''
    },
    nextDueDate(index) {
      return this.paymentMethodDate(index)
        ? this.$moment(this.paymentMethodDate(index)).format('DD/MM/YY')
        : ''
    },
    paymentMethodDate(index) {
      if (
        !this.computedCurrentPlans[index] ||
        !this.computedCurrentPlans[index].paymentMethod.date
      ) {
        return null
      }
      return this.computedCurrentPlans[index].paymentMethod.date
    },
    cancelSubscription(index) {
      switch (this.computedCurrentPlans[index].paymentMethod.name) {
        // TODO названия в константы
        case 'apple':
          this.showAppleStore()
          break
        case 'google':
          this.showPlayMarket()
          break
        default:
          this.showSimple(index)
      }
    },
    showAppleStore() {
      const buttons = []
      if (this.$store.getters['app/isMobile']) {
        buttons.push(
          {
            value: this.$t('web.go_to_settings'),
            onSubmited: () => {
              return new Promise((resolve) => {
                window.location.href = 'App-prefs://prefs:root=Settings'
                resolve()
              })
            },
          },
          {
            value: this.$t('web.cancel'),
          }
        )
      } else {
        buttons.push({
          value: this.$t('web.ok'),
        })
      }
      this.$store.dispatch('app/showDialog', {
        title: this.$t('web.dialog_cancel-subscription_header'),
        description: this.$t('web.dialog_cancel-subscription_title-AppStore'),
        buttons,
      })
    },
    showPlayMarket() {
      this.$store.dispatch('app/showDialog', {
        title: this.$t('web.dialog_cancel-subscription_header'),
        description: this.$t('web.dialog_cancel-subscription_title-PlayMarket'),
      })
    },
    showSimple(index) {
      this.$store.dispatch('app/showDialog', {
        title: this.$t('web.dialog_cancel-subscription_header'),
        description: this.$t('web.dialog_cancel-subscription_title-sure'),
        buttons: [
          {
            value: this.$t('web.yes'),
            onSubmited: () => {
              return new Promise((resolve) => {
                this.$api.user
                  .cancelSubscription(this.computedCurrentPlans[index].order_id)
                  .then(() => {
                    this.setCancellationProcessing(
                      this.computedCurrentPlans[index].order_id
                    )
                  })
                  .catch((error) => {
                    // Ужас. Нужно чтобы таких ифов не было
                    if (error.errors.data) {
                      this.$store.dispatch('app/showDialog', {
                        title: error.title,
                        type: 'error',
                        description: error.errors.data[0],
                      })
                    }
                  })
                  .finally(() => {
                    resolve()
                  })
              })
            },
          },
          {
            value: this.$t('web.no'),
          },
        ],
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/global/variables';
.tile-block {
  &:first-child {
    margin-bottom: 1.5rem;
  }
  &__title {
    font-weight: 500;
    margin: 0 0 1em 0;
  }
  &__item {
    margin: 0 0 0.5em 0;
  }
  &__link {
    transition: color $transTimeFast $easeDefault;
    cursor: pointer;
    color: var(--main);

    &:hover {
      color: var(--main-light);
    }
  }

  @media (max-width: 767.98px) {
    background-color: white;
    padding: 0;
    margin-bottom: 24px !important;
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 298;

  &__wrapper {
    position: relative;
  }

  &__progress-wrapper {
    position: relative;
    padding-bottom: 24px;
  }

  &__loading {
    position: absolute;
    z-index: 299;
    width: 100px;
    margin: 0 -50px;
    height: 24px;
    bottom: 0;
    &.loading {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .loading {
      &__dot {
        transform: scale(0, 0);
        border-radius: 50%;
        background-color: var(--main);
        animation: loading-dot 1s infinite ease-in-out;
        &--first {
          animation-delay: 0s;
          height: 7px;
          width: 7px;
        }
        &--second {
          animation-delay: 0.15s;
          height: 13px;
          width: 13px;
        }
        &--third {
          animation-delay: 0.3s;
          height: 19px;
          width: 19px;
        }
        &--fourth {
          animation-delay: 0.45s;
          height: 13px;
          width: 13px;
        }
        &--fifth {
          animation-delay: 0.6s;
          height: 7px;
          width: 7px;
        }
      }
    }
  }

  @keyframes loading-dot {
    0% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    35% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    70% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    100% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
  }
}
</style>
